<template>
  <div
    ref="btn"
    :class="[
      'footerlink',
      'span',
      hide ? 'hide' : '',
      animated ? 'btn_effect' : '',
    ]"
    @touchstart="animate($event)"
    @touchmove="determineTouchzone($event)"
    @touchend="click($event)"
    @click="click($event)"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'FooterLink',
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      animated: false,
      inTouchZone: false,
    }
  },
  methods: {
    animate(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.animated = true
    },
    determineTouchzone(event) {
      // we cannot measure touch zone on click events, return true
      if (event.type === "click") {this.inTouchZone = true; return true}
      // handle touch zone bounding box
      // this is important so we can properly distinguish between "hovering" over button and clicks
      var touch = event.touches[0] || event.changedTouches[0]

      const btn = this.$refs.btn
      const rect = btn.getBoundingClientRect()
      const x = touch.clientX
      const y = touch.clientY

      if (
        rect.top + rect.height > y &&
        rect.top < y &&
        rect.left < x &&
        rect.left + rect.width > x
      )
        this.inTouchZone = true
      else this.inTouchZone = false
    },
    click(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.determineTouchzone(event)
      this.animated = false
      if (this.inTouchZone) {
        this.$router.push({ path: this.link }).catch((failure) => {})
      }
    },
  },
}
</script>

<style lang="scss">
.footerlink {
  display: inline;
  color: #688194;
  text-decoration: underline;
  flex-basis: 0;
  flex: 1;
  align-self: auto;
  transition: all 0.2s ease-in-out;
  &.hide {
    visibility: hidden;
  }
  &:first-child {
    text-align: left;
  }
  &:nth-child(2) {
    text-align: center;
  }
  &:last-child {
    text-align: right;
  }
  &.btn_effect {
    transform: scale(0.95);
  }
  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.8rem;
  }
}
</style>
