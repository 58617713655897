<template>
  <div class="external_link">
    <div
      ref="span"
      :class="['span', hide ? 'hide' : '', animated ? 'btn_effect' : '']"
      @touchstart="animate($event)"
      @touchmove="determineTouchzone($event)"
      @touchend="open($event)"
      @click="open($event)"
    >
      {{ text }}
    </div>
    <NoConnection
      v-if="offline"
      @close="closeModal"
    />
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import NoConnection from '@components/NoConnection.vue'

export default {
  name: 'ExternalLink',
  components: {
    NoConnection,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    route: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      offline: false,
      scrollTop: '',
      inTouchZone: false,
      animated: false,
    }
  },
  watch: {
    offline: function (n, o) {
      if (n) {
        // When the modal is shown, we want a fixed body
        this.scrollTop = window.scrollY
        // document.body.style.position = 'fixed'
        // document.body.style.top = `-${this.scrollTop}px`
      } else {
        // When the modal is hidden, we want to remain at the top of the scroll position
        // document.body.style.position = ''
        // document.body.style.top = ''
        window.scrollTo(0, parseInt(this.scrollTop || '0'))
      }
    },
  },
  methods: {
    animate(event) {
      if (event.handled === false) return
      event.stopPropagation()
      // event.preventDefault()
      event.handled = true

      this.animated = true
    },
    determineTouchzone(event) {
      // we cannot measure touch zone on click events, return true
      if (event.type === "click") {this.inTouchZone = true; return true}
      // handle touch zone bounding box
      // this is important so we can properly distinguish between "hovering" over button and clicks
      var touch = event.touches[0] || event.changedTouches[0]

      const btn = this.$refs.span
      const rect = btn.getBoundingClientRect()
      const x = touch.clientX
      const y = touch.clientY

      if (
        rect.top + rect.height > y &&
        rect.top < y &&
        rect.left < x &&
        rect.left + rect.width > x
      )
        this.inTouchZone = true
      else this.inTouchZone = false
    },
    open(event) {
      // handle open link or route
      // if route is provided, we want to have InternalLink behaviour only on android PIPL
      // TO-DO: create a generalised Link component
      if (this.route.length > 0) {
        if (this.$compliance.isPlatform('android') && this.$compliance.isRegulation('PIPL')) {
          this.openInternalUrl(event)
        } else {
          this.openExternalUrl(event)
        }
      } else {
        this.openExternalUrl(event)
      }
    },
    openExternalUrl(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      Sentry.addBreadcrumb({
        type: 'user',
        category: 'ui.click',
        message: '[Link] clicked external url ' + this.url,
        timestamp: new Date(),
      })

      this.determineTouchzone(event)
      this.animated = false
      if (this.inTouchZone) {
        if (this.$compliance.offline) {
          this.offline = true
        } else {
          this.$compliance.openExternalUrl(this.url)
        }
      }
    },
    openInternalUrl(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      Sentry.addBreadcrumb({
        type: 'user',
        category: 'ui.click',
        message: '[Link] clicked internal url ' + this.route,
        timestamp: new Date(),
      })

      this.determineTouchzone(event)
      this.animated = false
      if (this.inTouchZone) {
        this.$router
            .push({ path: this.route })
            .catch((failure) => {})
      }
    },
    closeModal() {
      this.offline = false
    },
  },
}
</script>

<style lang="scss">
.external_link {
  display: inline-block;
  .span {
    text-decoration: underline;
    color: #6d8092;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    word-break: break-word;
  }
  .btn_effect {
    transform: scale(0.9);
  }
  &.block {
    display: block;
  }
}
</style>
