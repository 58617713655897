<template>
  <div
    ref="btn"
    :class="['btn', enable ? color : 'greyoff', animated ? 'btn_effect' : '']"
    @touchstart="animate($event)"
    @touchmove="determineTouchzone($event)"
    @touchend="action($event)"
    @click="action($event)"
  >
    <span>{{ text }}</span>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'

export default {
  name: 'Button',
  props: {
    color: {
      type: String,
      default: 'green',
    },
    enable: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      animated: false,
      inTouchZone: true,
    }
  },
  methods: {
    animate(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      if (this.enable) {
        this.animated = true
      }
    },
    determineTouchzone(event) {
      // we cannot measure touch zone on click events, return true
      if (event.type === "click") {this.inTouchZone = true; return true}
      // handle touch zone bounding box
      // this is important so we can properly distinguish between "hovering" over button and clicks
      var touch = event.touches[0] || event.changedTouches[0]
      const btn = this.$refs.btn
      const rect = btn.getBoundingClientRect()
      const x = touch.clientX
      const y = touch.clientY

      if (
        rect.top + rect.height > y &&
        rect.top < y &&
        rect.left < x &&
        rect.left + rect.width > x
      )
        this.inTouchZone = true
      else this.inTouchZone = false
    },
    action(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.determineTouchzone(event)
      if (this.enable) {
        this.animated = false
        if (this.inTouchZone) {
          this.$emit('clicked', true)
          Sentry.addBreadcrumb({
            type: 'user',
            category: 'ui.click',
            message: '[Button] clicked ' + this.text,
            timestamp: new Date(),
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
.btn_effect {
  transform: scale(0.9);
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 40px;
  margin: 0 auto;
  color: #688194;
  text-align: center;
  text-transform: uppercase;
  background-image: url('../assets/images/btn_green.png');
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.2s ease-in-out;
  @media screen and (min-width: 275px) {
    width: 94px;
    height: 40px;
  }
  @media screen and (min-width: 320px) {
    width: 94px;
    height: 40px;
  }
  @media screen and (min-width: 360px) {
    width: 110px;
    height: 48px;
  }
  @media screen and (min-width: 768px) {
    width: 165px;
    height: 72px;
  }
  @media screen and (min-width: 1024px) {
    width: 220px;
    height: 96px;
  }
  &.grey {
    background-image: url('../assets/images/btn_grey.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.greyoff {
    background-image: url('../assets/images/btn_grey_off.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.green {
    background-image: url('../assets/images/btn_green.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.white {
    background: #fff;
    border: 1px solid #d56146;
    border-radius: 10px;
    color: #d56146;
  }
  &.red {
    background: #d56146;
    border: 1px solid #d56146;
    border-radius: 10px;
    color: #fff;
  }
  &.white span, &.red span {
    margin-top: 0;
  }
  span {
    display: inline-block;
    width: 90%;
    font-size: 0.7em;
    font-weight: 700;
    line-height: 1em;
    @media screen and (min-width: 360px) {
      margin-top: -3px;
      font-size: 0.9em;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.4em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 1.7em;
    }
  }
}

.es .btn span,
.de .btn span,
.fr .btn span,
.tr .btn span,
.hi .btn span {
  @media screen and (min-width: 360px) {
    font-size: 0.7em;
    line-height: 1em;
    width: 80%;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.1em;
    line-height: 1em;
    width: 80%;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.4em;
    line-height: 1.2em;
  }
}
.ru .btn span {
  @media screen and (min-width: 360px) {
    font-size: 0.6em;
    line-height: 1em;
    width: 80%;
  }
  @media screen and (min-width: 768px) {
    font-size: 1em;
    line-height: 1em;
    width: 80%;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.4em;
    line-height: 1.2em;
  }
}
.ja .btn span {
  @media screen and (min-width: 360px) {
    font-size: 0.7em;
    line-height: 1em;
    width: 90%;
  }
  @media screen and (min-width: 768px) {
    font-size: 1em;
    line-height: 1.1em;
    width: 80%;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.4em;
    line-height: 1.2em;
  }
}
.vi .btn span {
  font-size: 0.6em;
  line-height: 1em;
  @media screen and (min-width: 320px) {
    font-size: 0.6em;
  }
  @media screen and (min-width: 360px) {
    font-size: 0.7em;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.1em;
    line-height: 1em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.5em;
  }
}
.landscape .optout_consent .btn, .adscontainer .btn {
  margin: 10px auto;
}
.landscape {
  .btn {

    @media screen and (min-width: 768px) {
      width: 110px;
      height: 48px;
    }
    @media screen and (min-width: 1024px) {
      width: 165px;
      height: 72px;
    }
    span {
      @media screen and (min-width: 768px) {
        font-size: 1em;
      }
      @media screen and (min-width: 1024px) {
        font-size: 1.2em;
      }
    }
  }
  &.tablet {
    .btn {
      @media screen and (min-width: 768px) {
        width: 165px;
        height: 72px;
      }
      @media screen and (min-width: 1024px) {
        width: 220px;
        height: 96px;
      }
      span {
        @media screen and (min-width: 768px) {
          font-size: 1.3em;
        }
        @media screen and (min-width: 1024px) {
          font-size: 1.6em;
        }
      }
    }
  }
}
.landscape .adscontainer .footer .global_consent_options .btn {
  @media screen and (min-width: 320px) {
    text-shadow: 1px 1px black;
    -webkit-text-stroke: initial;
  }
}
.landscape.tablet.de .btn span,
.landscape.tablet.fr .btn span,
.landscape.tablet.ru .btn span {
  @media screen and (min-width: 768px) {
    font-size: 1.1em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.3em;
  }
}

.landscape.ru .btn span {
  @media screen and (min-width: 768px) {
    font-size: 0.8em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.2em;
  }
}

.landscape.fr .btn span {
  @media screen and (min-width: 768px) {
    font-size: 0.7em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.1em;
  }
}

.landscape.de .btn span {
  @media screen and (min-width: 768px) {
    font-size: 0.8em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.1em;
  }
}
</style>
