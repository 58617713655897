<template>
  <div class="loading">
    <div class="spinner" />
  </div>
</template>

<script>
export default {
  name: 'Loading',
  methods: {},
}
</script>

<style lang="scss">
.loading {
  position: relative;
  background: transparent;
  text-align: center;
  width: 100%;
  height: 60px;

  .spinner {
    position: absolute;
    left: 50%;
    margin-left: -15px;
    top: 15px;
    background-image: url('../assets/images/spinner-blue.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    -webkit-animation: rotation 2s infinite linear;
    animation: rotation 2s infinite linear;
  }
  @-webkit-keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
</style>
