<template>
  <div class="noconnection_wrapper">
    <div class="noconnection">
      <div
        ref="btn"
        :class="['close', animated ? 'btn_effect' : '']"
        @touchstart="animate($event)"
        @touchmove="determineTouchzone($event)"
        @touchend="close($event)"
        @click="close($event)"
      >
        X
      </div>
      <img
        class="img"
        :src="
          publisher === 'hyperdot'
            ? require('../assets/images/lost_connection.png')
            : require('../assets/images/tom_confused.jpg')
        "
      >
      <div class="message">
        {{ content.no_internet }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoConnection',
  data() {
    return {
      content: {},
      animated: false,
      inTouchZone: false,
      publisher: 'outfit7',
    }
  },
  mounted() {
    this.content = this.$compliance.getLocalisedContent()
    this.publisher = this.$compliance.publisher
  },
  methods: {
    animate(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.animated = true
    },
    determineTouchzone(event) {
      // we cannot measure touch zone on click events, return true
      if (event.type === "click") {this.inTouchZone = true; return true}
      // handle touch zone bounding box
      // this is important so we can properly distinguish between "hovering" over button and clicks
      var touch = event.touches[0] || event.changedTouches[0]

      const btn = this.$refs.btn
      const rect = btn.getBoundingClientRect()
      const x = touch.clientX
      const y = touch.clientY

      if (
        rect.top + rect.height > y &&
        rect.top < y &&
        rect.left < x &&
        rect.left + rect.width > x
      )
        this.inTouchZone = true
      else this.inTouchZone = false
    },
    close(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.determineTouchzone(event)
      this.animated = true
      if (this.inTouchZone) {
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss">
.noconnection_wrapper {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);

  .noconnection {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
    display: block;
    width: 300px;
    height: 320px;
    margin-top: -160px;
    margin-left: -150px;
    background: #fff;
    border: 3px solid #000;
    border-radius: 20px;
    box-shadow: inset 0 0 20px 8px rgb(100 100 100 / 10%),
      1px 4px 12px 1px rgb(0 0 0 / 10%);
    .img {
      display: block;
      width: 100%;
      height: auto;
      padding: 30px 30px 10px 30px;
    }
    .message {
      padding: 0 30px 20px 30px;
      font-size: 1.1em;
      color: #4c5d6a;
      text-align: center;
    }
    .close {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 40px;
      height: 40px;
      text-indent: -9999px;
      background-image: url('../assets/images/btn_x.png');
      background-repeat: no-repeat;
      background-size: contain;
      transition: all 0.2s ease-in-out;
      transform: scale(1);
      &.btn_effect {
        transform: scale(0.9);
      }
    }
    @media screen and (min-width: 768px) {
      width: 600px;
      height: 680px;
      margin-top: -340px;
      margin-left: -300px;
      .close {
        width: 80px;
        height: 80px;
      }
      .message {
        font-size: 1.3em;
      }
    }
  }
}
.mythiclegends .noconnection, .triumphoflegends .noconnection {
  background: #241720;
  border-radius: 0;
  border: 3px solid #f3bf76;
  height: 300px;
  .message {
    color: #eadacb;
  }
  .close {
    background-image: url('../assets/images/ml_x.png');
    background-repeat: no-repeat;
    background-size: contain;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
  }
  .img {
    padding: 60px 70px 10px 70px;
  }
  @media screen and (min-width: 768px) {
    width: 600px;
    height: 600px;
    margin-top: -300px;
    margin-left: -300px;
    .close {
      width: 60px;
      height: 60px;
    }
    .message {
      font-size: 1.3em;
    }
    .img {
      padding: 100px 120px 10px 120px;
    }
  }
}
.swampattack2 .noconnection {
  background: #f4e4a6;
  border-radius: 0;
  border: 3px solid #54361f;
  height: 300px;
  .message {
    color: #54361f;
  }
  .close {
    background-image: url('../assets/images/sa2_x.png');
    background-repeat: no-repeat;
    background-size: contain;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
  }
  .img {
    padding: 60px 70px 10px 70px;
  }
  @media screen and (min-width: 768px) {
    width: 600px;
    height: 600px;
    margin-top: -300px;
    margin-left: -300px;
    .close {
      width: 60px;
      height: 60px;
    }
    .message {
      font-size: 1.3em;
    }
    .img {
      padding: 100px 120px 10px 120px;
    }
  }
}
.ru .noconnection .message {
  padding: 0 5px 20px 5px;
  font-size: 0.9em;
}
.landscape .noconnection_wrapper .noconnection {
  @media screen and (min-width: 768px) {
    width: 300px;
    height: 320px;
    margin-top: -160px;
    margin-left: -150px;
    .close {
      width: 40px;
      height: 40px;
    }
    .message {
      font-size: 1.1em;
    }
  }
}
</style>
