<template>
  <div class="footer">
    <div class="vendors">
      <FooterLink
        :hide="screen === 'partners'"
        :text="content.v_title"
        link="/tcf20/vendors/"
      />
      <FooterLink
        :hide="screen === 'legitimate-interest'"
        :text="content.li_title"
        link="/tcf20/legitimate-interest/"
      />
    </div>
    <GlobalAcceptReject
      ref="consentselect"
      :screen="screen"
    />
  </div>
</template>

<script>
import GlobalAcceptReject from '@components/tcf20/GlobalAcceptReject.vue'
import FooterLink from '@components/tcf20/FooterLink.vue'

export default {
  name: 'Footer',
  components: {
    GlobalAcceptReject,
    FooterLink,
  },
  props: {
    screen: {
      type: String,
      default: 'tcf20',
    },
  },
  data() {
    return {
      content: {},
    }
  },
  mounted() {
    this.content = this.$compliance.getLocalisedContent()
    this.$compliance.GlobalAcceptReject = this.$refs.consentselect
  },
}
</script>

<style lang="scss">
.footer {
  display: block;
  background: #fff;
  align-items: center;
  padding: 10px 20px 20px 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -10px 16px 16px #fff;
  transform: translateY(0);
  z-index: 100;
  .span {
    display: inline;
    color: #688194;
    text-decoration: underline;
    flex-basis: 0;
    flex: 1;
    align-self: auto;
    &.hide {
      visibility: hidden;
    }
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
  .vendors {
    position: relative;
    z-index: 101;
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0px 10px;
    z-index: 101;
    .span {
      color: #688194;
      text-decoration: underline;
      flex-basis: 0;
      flex: 1;
      align-self: auto;
      &:first-child {
        text-align: left;
      }
      &:nth-child(2) {
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.8rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 2.1rem;
      }
    }
  }
}

.absolutefooter .noniabcontainer .footer,
.absolutefooter .lgpdcontainer .footer,
.absolutefooter .tcf20container .footer {
  position: absolute;
  bottom: -20px;
  left: -20px;
  right: -20px;
}
.landscape {
  .footer {
    background: transparent;
    position: absolute;
    -webkit-transform: none;
    transform: none;
    box-shadow: none;
    .vendors {
      box-shadow: 0px -10px 16px 16px #fff;
      background: #fff;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      margin: 0 20px;
      .span {
        @media screen and (min-width: 768px) {
          font-size: 1.4rem;
        }
        @media screen and (min-width: 1024px) {
          font-size: 1.8rem;
        }
      }
    }
  }
  &.tablet {
    .footer .vendors .span {
      @media screen and (min-width: 768px) {
        font-size: 1.8rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 2.1rem;
      }
    }
  }
}
</style>
