<template>
  <div class="global_consent_options">
    <Button
      v-if="screen === 'tcf20'"
      :enable="true"
      color="grey"
      :text="content.a_options"
      @clicked="moreoptions"
    />
    <Button
      v-else
      :enable="true"
      color="grey"
      :text="content.a_reject"
      @clicked="reject"
    />
    <Button
      v-if="screen !== 'tcf20'"
      :enable="isSaveEnabled"
      :color="isSaveEnabled ? 'green' : 'grey'"
      :text="content.a_save"
      @clicked="save"
    />
    <Button
      :enable="true"
      color="green"
      :text="content.a_accept"
      @clicked="accept"
    />
  </div>
</template>

<script>
import Button from '@components/Button.vue'

export default {
  name: 'GlobalAcceptReject',
  components: {
    Button,
  },
  props: {
    screen: {
      type: String,
      default: 'tcf20',
    },
  },
  data() {
    return {
      isSaveEnabled: false,
      content: {},
    }
  },
  mounted() {
    this.content = this.$compliance.getLocalisedContent()
    if (this.$compliance.TCF20.isStateModified) {
      this.isSaveEnabled = true
    } else {
      this.isSaveEnabled = false
    }
  },
  methods: {
    update() {
      if (this.$compliance.TCF20.isStateModified) {
        this.isSaveEnabled = true
      } else {
        this.isSaveEnabled = false
      }
    },
    moreoptions() {
      this.$router.push({ path: '/tcf20/options/' }).catch((failure) => {})
    },
    accept() {
      this.$compliance.TCF20.acceptAll()
    },
    reject() {
      this.$compliance.TCF20.rejectAll(this.screen)
    },
    save() {
      if (this.isSaveEnabled) this.$compliance.TCF20.save()
    },
  },
}
</script>

<style lang="scss">
.global_consent_options {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.landscape .global_consent_options {
  position: fixed;
  right: 0;
  top: 50%;
  padding: 20px;
  transform: translateY(-50%);
  width: auto;
  margin: 0;
  display: block;
  justify-content: inherit;
}
</style>
