<template>
  <div
    v-if="module === 'noads'"
    class="global_consent_options"
  >
    <Button
      :enable="true"
      color="grey"
      :text="content.noads_decline"
      @clicked="reject"
    />
    <Button
      :enable="true"
      color="green"
      :text="content.noads_accept"
      @clicked="accept"
    />
  </div>
  <div
    v-else-if="module === 'pipl'"
    class="global_consent_options"
  >
    <Button
      v-if="screen === 'pipl'"
      :enable="true"
      color="grey"
      :text="content.pipl_no"
      @clicked="piplconfirm"
    />
    <Button
      v-else
      :enable="true"
      :color="screen === 'jinke' ? 'white' : 'grey'"
      :text="piplRejectButton"
      @clicked="reject"
    />
    <Button
      :enable="true"
      :color="screen === 'jinke' ? 'red' : 'green'"
      :text="piplAcceptButton"
      @clicked="accept"
    />
  </div>
  <div
    v-else
    class="global_consent_options"
  >
    <Button
      v-if="screen === 'noniab'"
      :enable="true"
      color="grey"
      :text="content.a_options"
      @clicked="moreoptions"
    />
    <Button
      v-else
      :enable="true"
      color="grey"
      :text="content.a_reject"
      @clicked="reject"
    />
    <Button
      v-if="screen !== 'noniab'"
      :enable="isSaveEnabled"
      :color="isSaveEnabled ? 'green' : 'grey'"
      :text="content.a_save"
      @clicked="save"
    />
    <Button
      :enable="true"
      color="green"
      :text="content.a_accept"
      @clicked="accept"
    />
  </div>
</template>

<script>
import Button from '@components/Button.vue'

export default {
  name: 'GlobalAcceptReject',
  components: {
    Button,
  },
  props: {
    module: {
      type: String,
      default: 'noniab',
    },
    screen: {
      type: String,
      default: 'noniab',
    },
  },
  data() {
    return {
      isSaveEnabled: false,
      content: {},
    }
  },
  computed: {
    piplAcceptButton() {
      if (this.screen === 'pipl') {
        return this.content.pipl_yes
      } else if (this.screen === 'jinke') {
        return this.content.pipl_accept_btn
      } else {
        return this.content.pipl_accept
      }
    },
    piplRejectButton() {
      if (this.screen === 'jinke') {
        return this.content.pipl_reject_btn
      } else {
        return this.content.pipl_exit
      }
    }
  },
  mounted() {
    this.content = this.$compliance.getLocalisedContent()
    if (this.$compliance.nonIAB && this.$compliance.nonIAB.isStateModified) {
      this.isSaveEnabled = true
    } else {
      this.isSaveEnabled = false
    }
  },
  methods: {
    update() {
      if (this.$compliance.nonIAB && this.$compliance.nonIAB.isStateModified) {
        this.isSaveEnabled = true
      } else {
        this.isSaveEnabled = false
      }
    },
    moreoptions() {
      if (this.module === 'lgpd') {
        this.$router
          .push({ path: '/consent-lgpd/options' })
          .catch((failure) => {})
      } else if (this.module === 'gdpr') {
        this.$router
          .push({ path: '/consent-gdpr/options' })
          .catch((failure) => {})
      }
    },
    accept() {
      if (this.module === 'pipl') {
        this.$compliance.Pipl.accept()
      } else {
        this.$compliance.nonIAB.acceptAll()
      }
    },
    reject() {
      if (this.module === 'pipl') {
        this.$compliance.Pipl.reject()
      } else {
        this.$compliance.nonIAB.rejectAll()
      }
    },
    piplconfirm() {
      this.$compliance.native('onEvent', {
        rejectedFirstPIPLConsentScreen: true,
        send: true,
      })
      this.$router.push({ path: '/pipl/confirm' }).catch((failure) => {})
    },
    save() {
      if (this.isSaveEnabled) this.$compliance.nonIAB.save()
    },
  },
}
</script>

<style lang="scss">
.global_consent_options {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.landscape .global_consent_options {
  position: fixed;
  right: 0;
  top: 50%;
  padding: 45px;
  transform: translateY(-50%);
  width: auto;
  margin: 0;
  display: block;
  justify-content: inherit;
  .btn {
    margin: 10px 0;
  }
}
</style>
