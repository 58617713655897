// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/btn_x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".topheader{position:fixed;top:0;left:0;right:0;text-align:center;width:100%;padding:env(safe-area-inset-top) 0 0 0;z-index:100}.topheader .close{position:absolute;right:calc(env(safe-area-inset-right) + 10px);top:calc(env(safe-area-inset-top) + 10px);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;transform:scale(1);text-indent:-9999px;width:34px;height:34px;transition:all .2s ease-in-out}.topheader .close.btn_effect{transform:scale(.9)}@media screen and (min-width:400px){.topheader .close{width:44px;right:calc(env(safe-area-inset-right) + 14px);top:calc(env(safe-area-inset-top) + 14px);height:44px}}@media screen and (min-width:768px){.topheader .close{right:calc(env(safe-area-inset-right) + 24px);top:calc(env(safe-area-inset-top) + 24px);width:69px;height:69px}}@media screen and (min-width:768px){.landscape .topheader .close{width:44px;right:calc(env(safe-area-inset-right) + 14px);top:calc(env(safe-area-inset-top) + 14px);height:44px}}@media screen and (min-width:1050px){.landscape .topheader h1{font-size:3.6em}.landscape .topheader .close{right:calc(env(safe-area-inset-right) + 24px);top:calc(env(safe-area-inset-top) + 24px);width:69px;height:69px}}@media screen and (min-width:768px){.landscape.tablet .topheader .close{right:calc(env(safe-area-inset-right) + 24px);top:calc(env(safe-area-inset-top) + 24px);width:69px;height:69px}}", ""]);
// Exports
module.exports = exports;
