<template>
  <div class="internal_link">
    <div
      ref="span"
      :class="['span', hide ? 'hide' : '', animated ? 'btn_effect' : '']"
      @touchstart="animate($event)"
      @touchmove="determineTouchzone($event)"
      @touchend="openInternalUrl($event)"
      @click="openInternalUrl($event)"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'

export default {
  name: 'InternalLink',
  props: {
    route: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inTouchZone: false,
      animated: false,
    }
  },
  methods: {
    animate(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.animated = true
    },
    determineTouchzone(event) {
      // we cannot measure touch zone on click events, return true
      if (event.type === "click") {this.inTouchZone = true; return true}
      // handle touch zone bounding box
      // this is important so we can properly distinguish between "hovering" over button and clicks
      var touch = event.touches[0] || event.changedTouches[0]

      const btn = this.$refs.span
      const rect = btn.getBoundingClientRect()
      const x = touch.clientX
      const y = touch.clientY

      if (
        rect.top + rect.height > y &&
        rect.top < y &&
        rect.left < x &&
        rect.left + rect.width > x
      )
        this.inTouchZone = true
      else this.inTouchZone = false
    },
    openInternalUrl(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      Sentry.addBreadcrumb({
        type: 'user',
        category: 'ui.click',
        message: '[Link] clicked internal url ' + this.route,
        timestamp: new Date(),
      })

      this.determineTouchzone(event)
      this.animated = false
      if (this.inTouchZone) {
        this.$router
            .push({ path: this.route })
            .catch((failure) => {})
      }
    },
  },
}
</script>

<style lang="scss">
.internal_link {
  display: inline-block;
  .span {
    text-decoration: underline;
    color: #6d8092;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
  }
  .btn_effect {
    transform: scale(0.9);
  }
}
</style>
