<template>
  <div class="topheader">
    <div
      v-if="back"
      ref="btn"
      :class="['close', animated ? 'btn_effect' : '']"
      @touchstart="animate($event)"
      @touchmove="determineTouchzone($event)"
      @touchend="goback($event)"
      @click="goback($event)"
    >
      X
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      default: '',
    },
    back: {
      type: Boolean,
      default: false,
    },
    regulation: {
      type: String,
      default: 'tcf20',
    },
  },
  data() {
    return {
      animated: false,
      inTouchZone: false,
      activeCollector: ''
    }
  },
  mounted() {
    this.activeCollector = this.$compliance.getActiveCollector()
    if (this.back) {
      document.addEventListener('keydown', this.handleKeyDown)
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown)
  },
  methods: {
    animate(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.animated = true
    },
    determineTouchzone(event) {
      // we cannot measure touch zone on click events, return true
      if (event.type === "click" || event.type === 'keydown') {this.inTouchZone = true; return true}
      // handle touch zone bounding box
      // this is important so we can properly distinguish between "hovering" over button and clicks
      var touch = event.touches[0] || event.changedTouches[0]

      const btn = this.$refs.btn
      const rect = btn.getBoundingClientRect()
      const x = touch.clientX
      const y = touch.clientY

      if (
        rect.top + rect.height > y &&
        rect.top < y &&
        rect.left < x &&
        rect.left + rect.width > x
      )
        this.inTouchZone = true
      else this.inTouchZone = false
    },
    goback(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true

      this.determineTouchzone(event)
      this.animated = false
      if (this.inTouchZone) {
        if (this.regulation === 'tcf20') {
          this.$router.push({ path: '/tcf20/options/' }).catch((failure) => {})
        } else if (this.regulation === 'gdpr') {
          this.$router
            .push({ path: '/consent-gdpr/options' })
            .catch((failure) => {})
        } else if (this.regulation === 'lgpd') {
          this.$router
            .push({ path: '/consent-lgpd/options' })
            .catch((failure) => {})
        } else if (this.regulation === 'pipl') {
          if (this.activeCollector === 'optout') {
            this.$router
                .push({ path: '/opt-out' })
                .catch((failure) => {})
          } else {
            this.$router
                .push({ path: '/pipl' })
                .catch((failure) => {})
          }
        } else if (this.regulation === 'exit') {
          this.$compliance.native('closeWebApp', true)
        }
      }
    },
    handleKeyDown(e) {
      // Keydown handler for PC games, goes back on ESC click
      if (e.key === 'Escape') {
        this.goback(e)
      }
    },
  },
}
</script>

<style lang="scss">
.topheader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  padding: env(safe-area-inset-top) 0 0 0;
  z-index: 100;

  .close {
    position: absolute;
    right: calc(env(safe-area-inset-right) + 10px);
    top: calc(env(safe-area-inset-top) + 10px);
    background-image: url('../assets/images/btn_x.png');
    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(1);
    text-indent: -9999px;
    width: 34px;
    height: 34px;
    transition: all 0.2s ease-in-out;
    &.btn_effect {
      transform: scale(0.9);
    }
  }
  @media screen and (min-width: 400px) {
    .close {
      width: 44px;
      right: calc(env(safe-area-inset-right) + 14px);
      top: calc(env(safe-area-inset-top) + 14px);
      height: 44px;
    }
  }
  @media screen and (min-width: 768px) {
    .close {
      right: calc(env(safe-area-inset-right) + 24px);
      top: calc(env(safe-area-inset-top) + 24px);
      width: 69px;
      height: 69px;
    }
  }
}
.landscape {
  .topheader {
    @media screen and (min-width: 768px) {
      .close {
        width: 44px;
        right: calc(env(safe-area-inset-right) + 14px);
        top: calc(env(safe-area-inset-top) + 14px);
        height: 44px;
      }
    }
    @media screen and (min-width: 1050px) {
      h1 {
        font-size: 3.6em;
      }
      .close {
        right: calc(env(safe-area-inset-right) + 24px);
        top: calc(env(safe-area-inset-top) + 24px);
        width: 69px;
        height: 69px;
      }
    }
  }
  &.tablet {
    .topheader {
      @media screen and (min-width: 768px) {
        .close {
          right: calc(env(safe-area-inset-right) + 24px);
          top: calc(env(safe-area-inset-top) + 24px);
          width: 69px;
          height: 69px;
        }
      }
    }
  }
}
</style>
